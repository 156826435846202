import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import LandingPage from "./components/LandingPage";
import AboutPage from "./components/AboutPage";
import HireResourcesPage from "./components/HireResourcesPage";
import ContactPage from "./components/ContactPage";
import Footer from "./components/Footer";
import HireAPro from "./components/HireAPro";
import "./static/styles/base.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/hire-resources" element={<HireResourcesPage />} />
          <Route path="/hireapro" element={<HireAPro />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
