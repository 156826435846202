import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"; // You can remove these imports as well if they are not used elsewhere in your code

const Footer = () => {
  return (
    <div>
      <footer className="footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h4>Devdom Labs</h4>
              <p>Your trusted partner for top developers and managed teams.</p>
            </div>
            <div className="col-md-3">
              <h4>Our Services</h4>
              {/* Commented out or removed the social-icons-container div */}
              {/* <div className="social-icons-container">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="https://github.com/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </div> */}
            </div>
            <div className="col-md-3">
              <h4>Links</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="/hire-resources/product-development">
                    Hire a Developer
                  </a>
                </li>
                <li>
                  <a href="/hire-resources/big-data">About Us</a>
                </li>
                <li>
                  <a href="/hire-resources/product-design">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>California</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="mailto:info@devdomlabs.com">
                    info@devdomlabs.com
                  </a>
                </li>
                <li>
                  <a href="tel:435-817-3517">(435) 817-3517</a>
                </li>
                <li>
                  <a href="/">123 Main St, Dallas, Texas USA</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="text-muted small mb-0">
                &copy; 2023 Devdom Labs. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
