import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import aboutImage from "../static/images/SVG/new_logo.svg";
import "../static/styles/AboutPage.css";
import mikeImg from "../static/images/mike-demo-img.jpg";

function AboutPage() {
  return (
    <div>
      <Container fluid className="about-page p-0">
        <Col>
          <div className="about-jumbotron">
            <h1>Devdom</h1>
            <hr />
            <h5>
              We've been committed to providing dedicated remote teams to
              engineer finest digital experiences
            </h5>
          </div>
        </Col>
      </Container>
      <Container className="about-us py-5">
        <Row>
          <Col md={6}>
            <section>
              <h2>About Us</h2>
              <p>
                DevDom provides Technology-based services for its clients,
                focusing on helping businesses in modernizing their
                infrastructure and optimizing operations. We help businesses
                thrive and be ready for new opportunities!
              </p>
            </section>
            <section>
              <h3>Our Mission</h3>
              <p>Empower and Assist you on your Digital Journey</p>
            </section>
            <section>
              <h3>Our Vision</h3>
              <p>Help you be the best in your moment</p>
              <p>Solutioning for the present and beyond</p>
            </section>
          </Col>
          <Col md={6}>
            <img
              src={aboutImage}
              alt="About Devdom Labs"
              className="about-img img-fluid"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Container className="testimonials">
            <Row className="mt-5">
              <Col>
                <h2>Testimonials</h2>
                <p>
                  "As someone who works in the real estate industry, I was
                  constantly looking for ways to increase my sales and grow my
                  business. That's when I connected with Devdom, and I couldn't
                  be more impressed with their services. I needed a solution for
                  integrating data between CallTools and GHL; and that's what
                  they built for me. Thanks to Devdom's innovative solutions, I
                  was able to increase my real estate sales by 80% within a
                  short period of time. One of the key features that helped me
                  achieve this result was their ability to allow me to reach
                  more potential clients, increase engagement and close more
                  deals. Overall, I would highly recommend Devdom to anyone
                  looking to increase their sales and grow their business. They
                  have an expert team of professionals who are dedicated to
                  helping you achieve your goals. Their innovative solutions and
                  being able to pivot when obstacles rose make them a top
                  company in my book!""
                </p>
                <div className="profile-div">
                  <div className="profile-card">
                    <div className="profile-picture">
                      <img src={mikeImg} alt="Profile" />
                    </div>
                    <div className="profile-info">
                      <h2>Mike</h2>
                      <p>CEO of real estate company</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
}

export default AboutPage;
