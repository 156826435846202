import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import Infographic from "./Infographics";
import Clients from "./Clients";
import "../static/styles/LandingPage.css";
import FadeIn from "./FadeIn";
import ZoomInEffect from "./Zoomin";
import mainImg from "../static/images/jumbotron_logo.jpg";
import mikeImg from "../static/images/mike-demo-img.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { useNavigate } from "react-router";

const LandingPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/hireapro");
  };

  let text =
    "Welcome to Devdom Labs, your gateway to cutting-edge \
  solutions! We specialize in software development, web design,\
  mobile app development, and more.";
  if (window.innerWidth > 786) {
    text +=
      "Our experts turn your vision\
    into reality, whether you're a startup or an established\
    enterprise. Unlock unlimited possibilities for your business\
    success now.";
  }

  return (
    <div>
      <ZoomInEffect>
        <Container className="landing-page p-0">
          <Row>
            <Col md={6}>
              <div className="jumbotron">
                <h1>Devdom Labs</h1>
                <hr />
                <h2>
                  We get <strong>IT</strong> done!
                </h2>
                <p style={{ color: "black" }}>{text}</p>
                {window.innerWidth < 786 && (
                  <img src={mainImg} alt="mainImg" className="main-img" />
                )}
                <Button
                  variant="primary"
                  className="custom-btn"
                  size="lg"
                  onClick={handleClick}
                >
                  Hire a Pro
                </Button>
              </div>
            </Col>
            {window.innerWidth > 786 && (
              <Col md={6}>
                <img src={mainImg} alt="mainImg" className="main-img" />
              </Col>
            )}
          </Row>
          <Row>
            {/*<div className="social-icons-container-landing">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>

              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>*/}
          </Row>
        </Container>
      </ZoomInEffect>
      <FadeIn>
        <Infographic />
      </FadeIn>
      <hr />
      <FadeIn>
        <Clients />
      </FadeIn>
      <hr />
      <FadeIn>
        <Container className="testimonials">
          <Row className="mt-5">
            <Col>
              <h2>Testimonials</h2>
              <p>
                "As someone who works in the real estate industry, I was
                constantly looking for ways to increase my sales and grow my
                business. That's when I connected with Devdom, and I couldn't be
                more impressed with their services. I needed a solution for
                integrating data between CallTools and GHL; and that's what they
                built for me. Thanks to Devdom's innovative solutions, I was
                able to increase my real estate sales by 80% within a short
                period of time. One of the key features that helped me achieve
                this result was their ability to allow me to reach more
                potential clients, increase engagement and close more deals.
                Overall, I would highly recommend Devdom to anyone looking to
                increase their sales and grow their business. They have an
                expert team of professionals who are dedicated to helping you
                achieve your goals. Their innovative solutions and being able to
                pivot when obstacles rose make them a top company in my book!""
              </p>
              <div className="profile-div">
                <div className="profile-card">
                  <div className="profile-picture">
                    <img src={mikeImg} alt="Profile" />
                  </div>
                  <div className="profile-info">
                    <h2>Mike</h2>
                    <p>CEO of real estate company</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </FadeIn>
      <hr />
      <FadeIn>
        <Container
          fluid
          className="p-0 m-0"
          style={{ backgroundColor: "#30333a" }}
        >
          <div className="hire-developer">
            <h1>Hire a Developer</h1>
            <p>
              If you're ready to take the next step in product development, we
              invite you to get in touch with us today. Let's work together to
              turn your vision into reality.
            </p>
            <Button
              variant="primary"
              className="custom-btn"
              size="lg"
              onClick={handleClick}
            >
              Hire a Professional Developer
            </Button>
          </div>
        </Container>
      </FadeIn>
    </div>
  );
};

export default LandingPage;
