import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faHandshake,
  faHouse,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import config from "../config";
import { useNavigate } from "react-router";

const Infographic = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contact");
  };
  return (
    <div className="infographic">
      <Container>
        <Row>
          <Col className="text-center info-main-text">
            <h1>
              Our <strong>Journey</strong>
            </h1>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="infographic-item">
            <FontAwesomeIcon
              icon={faHandshake}
              size={window.innerWidth > 768 ? "6x" : "4x"}
              color={config.primary_color}
            />
            <p className="number">10+ </p>
            <p>Clients</p>
          </Col>
          <Col className="infographic-item">
            <FontAwesomeIcon
              icon={faUserAlt}
              size={window.innerWidth > 768 ? "6x" : "4x"}
              color={config.primary_color}
            />
            <p className="number">10+</p>
            <p> Employees</p>
          </Col>

          <Col className="infographic-item">
            <FontAwesomeIcon
              icon={faHouse}
              size={window.innerWidth > 768 ? "6x" : "4x"}
              color={config.primary_color}
            />
            <p className="number">2 </p>
            <p>Offices</p>
          </Col>

          <Col className="infographic-item">
            <FontAwesomeIcon
              icon={faRotateRight}
              size={window.innerWidth > 768 ? "6x" : "4x"}
              color={config.primary_color}
            />
            <p className="number">2</p>
            <p> Repeat Clients</p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              variant="primary"
              className="custom-btn"
              size="lg"
              onClick={handleClick}
            >
              Contact US
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Infographic;
