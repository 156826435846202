import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../static/styles/HireAPro.scss";
import { Button } from "primereact/button";
import ZoomInEffect from "./Zoomin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowMaximize,
  faGear,
  faLayerGroup,
  faMobile,
  faInfinity,
  faChartSimple,
  faDiagramProject,
  faRobot,
  faCheckSquare,
  faPalette,
  faCloud,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import emailjs from "emailjs-com";

function HireAPro() {
  const [selectedButtons, setSelectedButtons] = useState([]);

  const [phoneNo, setPhoneNo] = useState();
  const handleButtonSelect = (buttonName) => {
    if (selectedButtons.includes(buttonName)) {
      setSelectedButtons(selectedButtons.filter((btn) => btn !== buttonName));
    } else {
      setSelectedButtons([...selectedButtons, buttonName]);
    }
  };

  const items = {
    "Front End": ["Front End", <FontAwesomeIcon icon={faWindowMaximize} />],
    "Back End": ["Back End", <FontAwesomeIcon icon={faGear} />],
    "Full Stack": ["Full Stack", <FontAwesomeIcon icon={faLayerGroup} />],
    Mobile: ["Mobile", <FontAwesomeIcon icon={faMobile} />],
    DevOps: ["DevOps", <FontAwesomeIcon icon={faInfinity} />],
    "Data Engineer": [
      "Data Engineer",
      <FontAwesomeIcon icon={faChartSimple} />,
    ],
    "Data Scientist": [
      "Data Scientist",
      <FontAwesomeIcon icon={faDiagramProject} />,
    ],
    "Machine Learning": [
      "Machine Learning",
      <FontAwesomeIcon icon={faRobot} />,
    ],
    "QA Engineer": ["QA Engineer", <FontAwesomeIcon icon={faCheckSquare} />],
    "UI/UX Designer": ["UI/UX Designer", <FontAwesomeIcon icon={faPalette} />],
    "Cloud Engineer": ["Cloud Engineer", <FontAwesomeIcon icon={faCloud} />],
    "Not Sure": ["Not Sure", <FontAwesomeIcon icon={faQuestion} />],
  };

  const buttons = () => {
    let buttonArray = [];
    for (const [key, value] of Object.entries(items)) {
      buttonArray.push(
        <span key={key}>
          <Button
            className={
              selectedButtons.includes(value[0])
                ? "my-button-selected"
                : "my-button-theme"
            }
            onClick={() => handleButtonSelect(value[0])}
          >
            <span className="role-icon">{value[1]}</span>
            {value[0]}
          </Button>
        </span>
      );
    }
    return buttonArray;
  };

  const SERVICE_ID = "service_19k6ncj";
  const TEMPLATE_ID = "template_70e2qur";
  const USER_ID = "MNTwEJrdhnZaINy1S";

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID);
    alert("Your message has been sent successfully!");
  };

  return (
    <div>
      <ZoomInEffect>
        <Container className="hire-a-pro py-5">
          <Row>
            <Col>
              <h3>Let's find your developer</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-muted">Please select atleast one role</p>
              {buttons()}
            </Col>
          </Row>

          <Row>
            <Col>
              <Form onSubmit={sendEmail}>
                <Container>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlEmail"
                      >
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="from_email"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        style={{ display: "none" }}
                        controlId="exampleForm.ControlEmail"
                      >
                        <Form.Label>Roles</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Roles"
                          name="role"
                          value={selectedButtons}
                        />
                      </Form.Group>
                      <Form.Group controlId="exampleForm.PhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phoneNo}
                          onChange={setPhoneNo}
                          name="from_phone_no"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Project Description</Form.Label>
                        <Form.Control as="textarea" rows={3} name="message" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    style={{ marginTop: "2rem" }}
                    type="submit"
                    label="Submit"
                    className="text-white custom-btn"
                  />
                </Container>
              </Form>
            </Col>
          </Row>
        </Container>
      </ZoomInEffect>
    </div>
  );
}

export default HireAPro;
