import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../static/styles/HireResource.css";
import LogoCaro from "./LogoCaro";
import phplogo from "../static/images/php-logo.png";
import reactlogo from "../static/images/react-logo.png";
import javascriptlogo from "../static/images/js-logo.png";
import androidlogo from "../static/images/android-logo.png";
import djangologo from "../static/images/django-logo.png";
import nodelogo from "../static/images/node-logo.png";
import ioslogo from "../static/images/ios-logo.png";
import pythonlogo from "../static/images/python-logo.png";
import wordpresslogo from "../static/images/wordpress-logo.png";
import awsdata from "../static/images/awsdata-logo.png";
import azurelogo from "../static/images/azure-logo.png";
import datamig from "../static/images/datamig-logo.png";
import datavisual from "../static/images/datavisual-logo.png";
import seaborn from "../static/images/seaborn-logo.png";
import ui from "../static/images/ui-logo.jpg";
import ux from "../static/images/ux-logo.jpg";
import figma from "../static/images/figma-logo.png";
import adobexd from "../static/images/adobexd-logo.png";
import wireframe from "../static/images/wireframe-logo.png";
import database from "../static/images/database-logo.png";
import qa from "../static/images/qa-logo.png";
import selenium from "../static/images/selenium-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

const HireResourcesPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/hireapro");
  };
  const devLogos = [
    phplogo,
    reactlogo,
    javascriptlogo,
    androidlogo,
    djangologo,
    nodelogo,
    ioslogo,
    pythonlogo,
    wordpresslogo,
  ];
  const bigDataLogos = [awsdata, azurelogo, datamig, datavisual, seaborn];
  const uiuxLogos = [ui, ux, figma, adobexd, wireframe];
  const qaLogos = [database, qa, selenium];

  return (
    <div className="hire-resources">
      <Container fluid className="hire-page p-0">
        <Col>
          <div className="hire-jumbotron">
            <h1>Hire Resource</h1>
            <hr />
            <h5>
              We offer end-to-end product design, development, and support
              services for businesses. Our team delivers high-quality,
              user-friendly products optimized for performance and scalability.
            </h5>
          </div>
        </Col>
      </Container>
      <Container className="hire">
        <h1 className="text-center">
          Hire a <span>Resource</span>
        </h1>
        <Row className="my-5">
          <Col className="hire-item">
            <h2>Product Development</h2>
            <p className="resource-content">
              We work with the latest tech stack for Custom Software
              Development, Mobile App Development, Web Application Development,
              Cloud Application Development
            </p>
            <LogoCaro logos={devLogos} />
            <Button
              className="hire-btn"
              variant="primary"
              onClick={handleClick}
            >
              Hire a Developer{" "}
              <span>
                <FontAwesomeIcon icon={faArrowRight} color="white" />
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="my-5">
          <Col className="hire-item">
            <h2>Big Data</h2>
            <p className="resource-content">
              We provide services to augment your existing platforms and
              solutions with the power of computer vision, data visualizations,
              predictive analysis and more
            </p>
            <LogoCaro logos={bigDataLogos} />
            <Button
              className="hire-btn"
              variant="primary"
              onClick={handleClick}
            >
              Hire a Data Analyst{" "}
              <span>
                <FontAwesomeIcon icon={faArrowRight} color="white" />
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="my-5">
          <Col className="hire-item">
            <h2>Product Design</h2>
            <p className="resource-content">
              We combine product innovation with optimum usability. Through our
              iterative design process and thorough prototyping, we create an
              engaging and human-centric design that is scalable and a joy to
              use. Our team stays involved from the discovery phase to the final
              milestones to ensure that no part of your vision is lost in
              translation.
            </p>
            <LogoCaro logos={uiuxLogos} />
            <Button
              className="hire-btn"
              variant="primary"
              onClick={handleClick}
            >
              Hire a Designer{" "}
              <span>
                <FontAwesomeIcon icon={faArrowRight} color="white" />
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="my-5">
          <Col className="hire-item">
            <h2>Product Support</h2>
            <p className="resource-content">
              We help ensure premium product quality by offering a comprehensive
              QA process which encompasses all stages of development. Our
              services consist of a combination of automated and manual testing,
              making sure your product follows up-to-date quality assurance
              standards.
            </p>
            <LogoCaro logos={qaLogos} />
            <Button
              className="hire-btn"
              variant="primary"
              onClick={handleClick}
            >
              Hire a SQA Engineer{" "}
              <span>
                <FontAwesomeIcon icon={faArrowRight} color="white" />
              </span>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HireResourcesPage;
