import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../static/styles/ContactPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";
import config from "../config";
import ZoomInEffect from "./Zoomin";

function ContactPage() {
  const email = config.email;
  const phoneNo = config.phone;

  const SERVICE_ID = "service_19k6ncj";
  const TEMPLATE_ID = "template_hzroimn";
  const USER_ID = "MNTwEJrdhnZaINy1S";

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID);
    alert("Your message has been sent successfully!");
  };

  return (
    <ZoomInEffect>
      <div>
        <Container className="contact-us">
          <h1>Reach Us</h1>
          <Row>
            <Col md={6}>
              <div className="contact-info">
                <div className="contact-info-item">
                  <p>
                    <FontAwesomeIcon
                      className="contact-icon"
                      icon={faEnvelope}
                    />{" "}
                    Send a Message
                  </p>
                </div>
                <div
                  className="contact-info-item"
                  onClick={(e) => {
                    window.location.href = "tel:" + phoneNo;
                    e.preventDefault();
                  }}
                >
                  <p>
                    <FontAwesomeIcon className="contact-icon" icon={faPhone} />
                    {phoneNo}
                  </p>
                </div>

                <div
                  className="contact-info-item"
                  onClick={(e) => {
                    window.location.href = "mailto:" + email;
                    e.preventDefault();
                  }}
                >
                  <p>
                    <FontAwesomeIcon className="contact-icon" icon={faAt} />{" "}
                    {email}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Form onSubmit={sendEmail}>
                <Form.Group
                  className="contact-form-grp"
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    name="from_email"
                    type="email"
                    placeholder="Email"
                  />
                </Form.Group>
                <Form.Group
                  className="contact-form-grp"
                  controlId="formBasicCompany"
                >
                  <Form.Control
                    name="company"
                    type="text"
                    placeholder="Company"
                  />
                </Form.Group>
                <Form.Group
                  className="contact-form-grp"
                  controlId="formBasicMessage"
                >
                  <Form.Control
                    className="contact-form-grp-input"
                    as="textarea"
                    name="message"
                    rows={5}
                    placeholder="How can we help you?"
                  />
                </Form.Group>
                <Button className="contact-btn" variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </ZoomInEffect>
  );
}

export default ContactPage;
