import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import c1 from "../static/images/c1.png";
import c2 from "../static/images/c2.png";
import c3 from "../static/images/c3.png";
import c4 from "../static/images/c4.png";
import c5 from "../static/images/c5.png";
import c6 from "../static/images/c6.png";
import c7 from "../static/images/c7.png";
import c8 from "../static/images/c8.png";
import c9 from "../static/images/c9.png";
import c10 from "../static/images/c10.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Clients = () => {
  const logos = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };
  return (
    <div className="clients-main">
      <Container className="clients">
        <Row>
          <Col>
            {/* Images of all the clients here */}
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              showDots={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              autoPlaySpeed={5000}
              autoPlay={true}
            >
              {logos.map((logo, index) => (
                <div key={index} style={{ height: "300px" }}>
                  <img className="client-img mt-auto" src={logo} alt="logo" />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Clients;
