import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logoImage from "../static/images/SVG/new_logo_no_tag.svg";
import "../static/styles/Navigation.css";

const Navigation = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  return (
    <Navbar
      className={colorChange ? "scrolled" : "not-scrolled"}
      fixed="top"
      expand="lg"
    >
      <Navbar.Brand href="/" className="me-auto">
        {" "}
        <img
          src={logoImage}
          width="100"
          height="80"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar" />
      <Navbar.Collapse id="navbar">
        <Nav className="nav-links ms-auto">
          <Nav.Link className="hover-underline-animation" href="/contact">
            Discovery Call
          </Nav.Link>
          <Nav.Link className="hover-underline-animation" href="/hireapro">
            Hire a Developer
          </Nav.Link>

          <Nav.Link className="hover-underline-animation" href="/about">
            About Us
          </Nav.Link>
          <Nav.Link className="hover-underline-animation" href="/contact">
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
