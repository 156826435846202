import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const LogoCaro = ({ logos }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };
  return (
    <div className="clients-main">
      <Container className="clients">
        <Row>
          <Col>
            {/* Images of all the clients here */}
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              showDots={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              autoPlaySpeed={1000}
              autoPlay={true}
            >
              {logos.map((logo, index) => (
                <div key={index} style={{ height: "100px" }}>
                  <img className="client-img mt-auto" src={logo} alt="logo" />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default LogoCaro;
